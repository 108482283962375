import React from "react";
import { graphql } from "gatsby";
import { Text } from "~/components/Text";
import { ChapterPageProps } from "~/types/chapter";
import DownloadLinks from "~/components/DownloadLinks";
import { Box, Container, Grid } from "~/components/Layout";
import Footer from "~/components/Footer/index";

const DownloadPageTemplate: React.FC<ChapterPageProps> = ({ data: { page } }) => {
  const [brand] = page.brand;
  const { footer: [footer] = [] } = brand;

  return (
    <>
      <Container py="3xl">
        <Grid gridTemplateColumns="repeat(1, 1fr)" gridTemplateRows="masonry">
          {brand.chapters.flatMap((chapter) =>
            chapter.sections.map(({ download_section }) => {
              if (!download_section) return null;

              return (
                <Box key={download_section?.heading} as="section" mb="5xl">
                  <Text typeStyle="heading-xl" mb="sm" textColor="secondary">
                    {download_section?.heading}
                  </Text>
                  <DownloadLinks assets={download_section?.download.individual_downloads} />
                </Box>
              );
            }),
          )}
        </Grid>
      </Container>
      <Footer {...footer} />
    </>
  );
};

export default DownloadPageTemplate;

export const query = graphql`
  query($language: String!, $uid: String!) {
    ...MetaFragment
    ...HeaderFragment

    # This is the query that passes data to the page via the data property.
    page: contentstackDownload(publish_details: { locale: { eq: $language } }, uid: { eq: $uid }) {
      __typename
      meta {
        page_title
        page_description
      }
      brand {
        title
        meta {
          title
        }
        slug
        logo {
          ...Image
        }
        chapters {
          slug
          meta {
            page_title
          }
          sections {
            download_section {
              heading
              label
              download {
                individual_downloads {
                  ...Download
                }
                package_download {
                  ...Download
                }
              }
              image {
                ...Image
              }
            }
          }
        }
        footer {
          ...Footer
        }
      }
    }
    # Mandatory query for i18next. All pages need this query.
    ...TranslationQueryFragment
  }
`;
